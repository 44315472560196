<template>
    <div class="shop-list">
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
  import { COMPANY_ID } from '@/common/constant'
  import { useRouter } from 'vue-router'
  import store from '@/store'
  import dayjs from 'dayjs'
  import emitter from '@/utils/eventbus'
  import { ElMessage } from 'element-plus'
  import { shopMeiTuanAuth } from '@/service/main/shop'
  export default defineComponent({
    props: {},
    components: {
    },
    name: "empower",
    setup() {
      const router = useRouter()
      let appKey = '6d3bb9f77393789f'
      const getQuery = () => {
        var url = window.location.href;
        //切割URL字符串，获取参数auth_code和redirect_url
        let auth_code;
        if(url.indexOf('?') != -1){
          let arr = url.split('?')
          let arr1 = arr[1].split('&')
          // auth_code = arr1[1].split('=')[1]
          auth_code = arr1[0].split('=')[1]
          // console.log(url);
        }
        // console.log("auth_code",auth_code);
        // return
        if(auth_code)  {
          let data = {
            auth: auth_code
          }
          shopMeiTuanAuth(data).then(res => {  //把两个参数传给接口
              //授权成功
              if (res.code==0) {
                ElMessage({
                  message: '绑定成功!',
                  type: 'success'
                })
                router.replace({
                  path: '/main/bindshop/list'
                })
              }else{
                ElMessage({
                  message: res.msg,
                  type: 'error'
                })
              }
          })
        }
      }
      const initPage = () => {
        var url = window.location.href
        if(url.indexOf('state') != -1){
          getQuery()
        }else{
          window.location.href = `https://e.dianping.com/dz-open/merchant/auth?app_key=${appKey}&state=true&redirect_url=${encodeURIComponent(url)}`
        }
      }
      initPage()
  
      return {
        COMPANY_ID,
        router,
        dayjs,
        getQuery
      }
    }
  })
  </script>
  
  <style scoped lang="less">
  </style>
  